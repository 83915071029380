<template>
	<div>
		<div class="generic-title">{{ article.title }}</div>
		<div class="legal-content">
			<div v-html="article.text"></div>
		</div>
	</div>
</template>

<script>

export default {

	name: 'BlogArticlePage',

	async asyncData({ app, redirect, route }) {
		try {
			const { data } = await app.$api.get(`/blog/${route.params.slug}`);
			return {
				article: data,
			};
		} catch (e) {
			return redirect('/');
		}
	},

	data() {
		return {
			article: {},
		};
	},

};

</script>
